import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { SearchCondition } from '../../../src/app/shared/SearchCondition';
import { SubscriberFilter } from '../subscribers/SubscriberFilter';
import { SubscriberSearchSessionStatus } from '../subscribers/subscriber_search_session_status';
import { SubscriberSearchType } from './subscriber_search_type';

export interface SubscriberSearchQuery {
  name?: string;
  group?: string;
  imsi?: string;
  msisdn?: string;
  iccid?: string;
  simId?: string;
  serialNumber?: string;
  status?: string;
  tag?: string;
  bundles?: string;
  moduleTypes?: string;
  subscriptions?: string;
}

export class SubscriberSearchQueryParam {
  constructor(
    public query: SubscriberSearchQuery,
    public limit: number,
    public lastEvaluatedKey: string,
    public searchType: SubscriberSearchType,
    public sessionStatus: SubscriberSearchSessionStatus,
  ) {}

  static getValueWithDefault(given: string, sessionStatus: SubscriberSearchSessionStatus): string {
    if (sessionStatus === SubscriberSearchSessionStatus.NA) {
      return given;
    }

    if (given.length <= 2) {
      // @ts-expect-error (legacy code incremental fix)
      return undefined;
    }

    return given;
  }

  static createSubscriberSearchNameQueryParam(
    name: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        name: this.getValueWithDefault(name, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchIccidQueryParam(
    iccid: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        iccid: this.getValueWithDefault(iccid, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchSimIdQueryParam(
    simId: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        simId: this.getValueWithDefault(simId, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchIMSIQueryParam(
    imsi: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        imsi: this.getValueWithDefault(imsi, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchMultipleIMSIQueryParam(imsiList: string[]): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      { imsi: imsiList.join(',') },
      imsiList.length,
      // @ts-expect-error (legacy code incremental fix)
      null,
      SubscriberSearchType.OR,
      SubscriberSearchSessionStatus.NA,
    );
  }

  static createSubscriberSearchMSISDNQueryParam(
    msisdn: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        msisdn: this.getValueWithDefault(msisdn, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchSerialNumberQueryParam(
    serialNumber: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        serialNumber: this.getValueWithDefault(serialNumber, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchGroupQueryParam(
    group: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        group: this.getValueWithDefault(group, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchTagValueParam(
    tag: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        tag: this.getValueWithDefault(tag, subscriberSearchSessionStatus),
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.AND,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchStatusParam(
    filter: SubscriberFilter,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    return new SubscriberSearchQueryParam(
      {
        status: filter.status,
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.OR,
      subscriberSearchSessionStatus,
    );
  }

  static createSubscriberSearchAnyQueryParam(
    query: string,
    limit: number,
    subscriberSearchSessionStatus: SubscriberSearchSessionStatus,
    lastEvaluatedKey: string,
  ): SubscriberSearchQueryParam {
    const q = this.getValueWithDefault(query, subscriberSearchSessionStatus);
    return new SubscriberSearchQueryParam(
      {
        name: q,
        group: q,
        imsi: q,
        msisdn: q,
        iccid: q,
        serialNumber: q,
        tag: q,
        bundles: q,
      },
      limit,
      lastEvaluatedKey,
      SubscriberSearchType.OR,
      subscriberSearchSessionStatus,
    );
  }

  static createUnifiedSearchQueryParam(searchCondition: SearchCondition, limit: number, lastEvaluatedKey: string) {
    let query: SubscriberSearchQuery = {
      status: searchCondition.status?.join(','),
      moduleTypes: searchCondition.moduleType?.join(','),
      subscriptions: searchCondition.subscription?.join(','),
    };

    if (searchCondition.text?.value) {
      if (searchCondition.text.category === 'any') {
        const q = searchCondition.text.value;
        query = {
          name: q,
          group: q,
          imsi: q,
          msisdn: q,
          iccid: q,
          simId: q,
          serialNumber: q,
          tag: q,
        };
      } else {
        query[searchCondition.text.category] = searchCondition.text.value;
      }
    }

    return new SubscriberSearchQueryParam(
      query,
      limit,
      lastEvaluatedKey,
      searchCondition.searchType,
      searchCondition.sessionStatus || SubscriberSearchSessionStatus.NA,
    );
  }

  toQuery(options: { isSim?: boolean } = {}): { [key: string]: any } {
    const query = this.query;
    const status = options.isSim && this.query.status ? this.query.status.split('|').join(',') : undefined;
    const moduleTypes = options.isSim ? this.query.moduleTypes : undefined;
    const sim_id = options.isSim ? this.query.simId : undefined;
    const subscriptions = options.isSim ? this.query.subscriptions : undefined;
    return {
      name: query.name,
      group: query.group,
      imsi: query.imsi,
      msisdn: query.msisdn,
      iccid: query.iccid,
      serial_number: query.serialNumber,
      sim_id,
      tag: query.tag,
      bundles: query.bundles,
      status,
      module_type: moduleTypes,
      subscription: subscriptions,
      limit: this.limit,
      last_evaluated_key: this.lastEvaluatedKey,
      session_status: this.sessionStatus,
      search_type: this.searchType.toString(),
    };
  }

  toSearchQuery(options: { isSim?: boolean } = {}): SearchQuery {
    const searchQuery = new SearchQuery();
    const query = this.toQuery(options);
    Object.keys(query).forEach((key) => {
      searchQuery.setQueryItem(key, query[key]);
    });
    return searchQuery;
  }
}
