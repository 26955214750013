import { LegacyAny } from '@soracom/shared/core';

import {
  getBuiltInTimeRange,
  isAbsoluteTimeRange,
  isBuiltinTimeRange,
  isBuiltinTimeRangeKey,
  TimeRange,
} from '../soracom-ui/datetime/datetime-range-selector/datetime-range.type';
import { HarvestDataVizType, isHarvestDataVizType } from './harvest-data-viz/harvest-data-viz-type';
import { HarvestDataState } from './harvest-data.store';
import { HarvestResource, isHarvestResourceType } from './harvest-data.type';

/**
 * Harvest data url Search Param (encoded)
 */
export type HarvestDataUrlSearchParam = {
  resource_type?: string;
  resource_id?: string;
  resources?: string;
  from?: string;
  to?: string;
  timerange?: string;
  ar?: string | boolean;
  viz_type?: string;
  items_per_page?: string;
};

// can we do this in smarter way?
export const HARVEST_DATA_URL_SEARCH_KEYS: (keyof HarvestDataUrlSearchParam)[] = [
  'resource_type',
  'resource_id',
  'resources',
  'from',
  'to',
  'timerange',
  'ar',
  'viz_type',
  'items_per_page',
];

/**
 * Internal representation of HarvestDataUrlSearchParam (decoded)
 */
export type HarvestDataParam = Partial<
  Pick<HarvestDataState, 'resources' | 'timeRange' | 'autoRefreshEnabled' | 'vizType' | 'itemsPerPage'>
>;

export const encodeHarvestDataRouteParams = (state: HarvestDataParam): HarvestDataUrlSearchParam => {
  const param: HarvestDataUrlSearchParam = {};

  // @ts-expect-error (legacy code incremental fix)
  if (state.resources.length === 1) {
    // @ts-expect-error (legacy code incremental fix)
    param.resource_type = state.resources[0].resourceType;
    // @ts-expect-error (legacy code incremental fix)
    param.resource_id = state.resources[0].resourceId;
    // @ts-expect-error (legacy code incremental fix)
  } else if (state.resources.length > 1) {
    // @ts-expect-error (legacy code incremental fix)
    param.resources = encodeResources(state.resources);
  }

  if (state.timeRange) {
    const tr = state.timeRange;
    if (isAbsoluteTimeRange(tr)) {
      if (tr.from) {
        param.from = String(tr.from); // NOTE: from can be 'null'
      }
      if (tr.to) {
        param.to = String(tr.to); // NOTE: to can be 'null'
      }
    } else if (isBuiltinTimeRange(tr)) {
      param.timerange = tr.key;
    }
  }

  if (state.vizType) {
    param.viz_type = state.vizType;
  }

  if (state.autoRefreshEnabled) {
    param.ar = true;
  }

  if (state.itemsPerPage) {
    param.items_per_page = String(state.itemsPerPage | 0);
  }

  return param;
};

const RESOURCES_SEPARATOR = '&';
const RESOURCE_TYPE_ID_SEPARATOR = '#';

const encodeResources = (resources: HarvestResource[]): string =>
  resources.map((r) => r.resourceType + RESOURCE_TYPE_ID_SEPARATOR + r.resourceId).join(RESOURCES_SEPARATOR);

export const decodeHarvestDataRouteParams = (urlQueryParams: HarvestDataUrlSearchParam): HarvestDataParam => {
  const resources = decodeResourcesRouteParam(urlQueryParams);
  const timeRange = decodeTimeRangeRouteParam(urlQueryParams);
  const autoRefreshEnabled = decodeAutoRefreshRouteParam(urlQueryParams);
  const vizType = decodeVizTypeRouteParam(urlQueryParams);
  const itemsPerPage = decodeItemsPerPageRouteParam(urlQueryParams);
  return { resources, timeRange, autoRefreshEnabled, vizType, itemsPerPage };
};

const decodeTimeRangeRouteParam = (urlQueryParams: HarvestDataUrlSearchParam): TimeRange | undefined => {
  const { from, to, timerange } = urlQueryParams;
  if (from || to) {
    // 'null' and other invalid values will be evaluated as `null`, meaning an open-ended timerange
    // @ts-expect-error (legacy code incremental fix)
    const [fromValue, toValue] = [from, to].map((str) => Number.parseInt(str)).map((n) => (Number.isFinite ? n : null));
    return { from: fromValue, to: toValue };
  } else if (isBuiltinTimeRangeKey(timerange)) {
    // @ts-expect-error (legacy code incremental fix)
    return getBuiltInTimeRange(timerange) || undefined;
  }
  return undefined;
};

const decodeAutoRefreshRouteParam = (urlQueryParams: HarvestDataUrlSearchParam): boolean | undefined => {
  const { ar } = urlQueryParams;
  return ar ? true : undefined;
};

const decodeVizTypeRouteParam = (urlQueryParams: HarvestDataUrlSearchParam): HarvestDataVizType | undefined => {
  const { viz_type } = urlQueryParams;
  // @ts-expect-error (legacy code incremental fix)
  return isHarvestDataVizType(viz_type) ? viz_type : undefined;
};

const decodeItemsPerPageRouteParam = (urlQueryParams: HarvestDataUrlSearchParam): number | undefined => {
  const { items_per_page } = urlQueryParams;
  // @ts-expect-error (legacy code incremental fix)
  const n = Number.parseInt(items_per_page);
  return Number.isFinite(n) && n > 0 ? n : undefined;
};

const decodeResourcesRouteParam = (urlQueryParams: HarvestDataUrlSearchParam): HarvestResource[] | undefined => {
  const { resource_id, resource_type, resources } = urlQueryParams;

  let ret: LegacyAny = undefined;

  if (resource_id && resource_type) {
    const rsc = genHarvestResource(resource_type, resource_id);
    if (rsc) {
      ret = [rsc];
    }
  } else if (resources) {
    ret = resources
      .split(RESOURCES_SEPARATOR)
      .map((resourceStr) => resourceStr.split(RESOURCE_TYPE_ID_SEPARATOR))
      .filter((v) => v.length === 2)
      .map(([type, id]) => genHarvestResource(type, id))
      .filter((v) => !!v);
  }
  return ret;
};

const genHarvestResource = (
  resourceType: string | undefined,
  resourceId: string | undefined
): HarvestResource | null => {
  // @ts-expect-error (legacy code incremental fix)
  if (isHarvestResourceType(resourceType) && resourceId && resourceId.length > 0) {
    return { resourceType, resourceId };
  }
  return null;
};
