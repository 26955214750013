import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ClickCapturesPositionDirective } from "@soracom/shared-ng/ui-sds";
import { TextContentPipeModule } from "@soracom/shared-ng/util-common";
import { SoracomCommonModule } from "../soracom-common/soracom-common.module";
import { SoracomUiModule } from "../soracom-ui/soracom-ui.module";
import { SimsSearchBoxComponent } from "./sims-search-box/sims-search-box.component";

@NgModule({
  declarations: [SimsSearchBoxComponent],
  exports: [SimsSearchBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SoracomCommonModule,
    SoracomUiModule,
    ClickCapturesPositionDirective,
    TextContentPipeModule,
  ],
})
export class SimsModule {}
