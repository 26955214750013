import { LegacyAny } from '@soracom/shared/core';

import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormControlOptions, FormControlState, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { minNonSpaceCharValidator } from '@soracom/shared-ng/util-common';
import {
  SubscriberModuleType,
  SubscriberStatus,
  SubscriptionPlan,
  SubscriptionPlanInterface,
} from '@soracom/shared/subscription-plan';
import {
  SubscriberSearchSessionStatus,
  SubscriberSearchSessionStatusFactory,
} from 'apps/user-console/app/shared/subscribers/subscriber_search_session_status';
import { isEqual } from 'lodash-es';
import { debounceTime } from 'rxjs';
import { CoverageTypeService, getOperatorData } from '@soracom/shared/data-access-auth';
import { FeatureVisibilityService } from '@soracom/shared/data-access-auth';
import { SubscriberSearchQuery } from '../../../../app/shared/components/subscriber_search_query_param';
import { SubscriberSearchType } from '../../../../app/shared/components/subscriber_search_type';
import { SearchCondition } from '../../shared/SearchCondition';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { KddiDepartmentCode } from '@soracom/shared/kddi-oem-platform';
import { SubscriptionPlanName } from '@foundation/soracom-platform';

type TextSearchCategory = keyof SubscriberSearchQuery | 'any';

const textSearchCategories: ReadonlyArray<TextSearchCategory> = [
  'any',
  'name',
  'group',
  'simId',
  'imsi',
  'msisdn',
  'iccid',
  'serialNumber',
  'tag',
];

type SubscriberStatusForSearch = Exclude<SubscriberStatus, 'transferring'>;

const statuses: ReadonlyArray<SubscriberStatusForSearch> = [
  'standby',
  'ready',
  'active',
  'inactive',
  'suspended',
  'terminated',
  'shipped',
];

const moduleTypes: ReadonlyArray<SubscriberModuleType> = [
  'mini',
  'micro',
  'nano',
  'trio',
  'embedded',
  'integrated',
  'virtual',
  'profilePackage',
];

const subscriptionsMap: { [key: string]: SubscriptionPlanInterface[] } = {
  jp: [
    SubscriptionPlan.get('plan-D'),
    SubscriptionPlan.get('plan-DU'),
    SubscriptionPlan.get('plan-K'),
    SubscriptionPlan.get('plan-KM1'),
    SubscriptionPlan.get('planArc01'),
  ],
  g: [
    SubscriptionPlan.get('plan01s'),
    SubscriptionPlan.get('plan01s-low_data_volume'),
    SubscriptionPlan.get('planP1'),
    SubscriptionPlan.get('plan-US-max'),
    SubscriptionPlan.get('planX1'),
    SubscriptionPlan.get('planX2'),
    SubscriptionPlan.get('planX3'),
    SubscriptionPlan.get('planX3-EU'),
    SubscriptionPlan.get('plan-NA1-package'),
    SubscriptionPlan.get('planArc01'),
  ],
  kddigcp: [
    SubscriptionPlan.get('planGLK1'),
    SubscriptionPlan.get('plan01s'),
    SubscriptionPlan.get('plan01s-low_data_volume'),
    SubscriptionPlan.get('planP1'),
    SubscriptionPlan.get('plan-US-max'),
    SubscriptionPlan.get('planX1'),
    SubscriptionPlan.get('planX2'),
    SubscriptionPlan.get('plan-NA1-package'),
    SubscriptionPlan.get('planArc01'),
  ],
  kddikia: [SubscriptionPlan.get('planJPK1'), SubscriptionPlan.get('planArc01')],
};

type SessionStatusForSearch = 'online' | 'offline' | null;
const sessionStatuses: ReadonlyArray<SessionStatusForSearch> = ['online', 'offline'];

type SearchConditionFormValue = {
  text: {
    enabled: boolean;
    category: TextSearchCategory;
    text: string | null;
  };
  moduleType: {
    enabled: boolean;
    condition: Record<SubscriberModuleType, boolean>;
  };
  status: {
    enabled: boolean;
    condition: Record<SubscriberStatusForSearch, boolean>;
  };
  subscription: {
    enabled: boolean;
    // Partial because available subscription plans varies depending on user & coverage_type
    condition: Partial<Record<SubscriptionPlanName, boolean>>;
    other: string;
  };
  sessionStatus: {
    enabled: boolean;
    condition: SessionStatusForSearch;
  };
};

/**
 * Convert a given SearchCondition to a normalized SearchConditionFormValue
 */
function convertSearchConditionToFormValue(
  cond: Readonly<Partial<SearchCondition>> | null | undefined,
  defaultValue: Readonly<SearchConditionFormValue>,
): SearchConditionFormValue {
  const formValue: SearchConditionFormValue = structuredClone(defaultValue);

  // @ts-expect-error (legacy code incremental fix)
  if (cond?.text?.value?.length > 0) {
    formValue.text = {
      enabled: true,
      category: textSearchCategories.find((c) => c === cond?.text?.category) ?? 'any',
      // @ts-expect-error (legacy code incremental fix)
      text: cond.text.value,
    };
  }
  // @ts-expect-error (legacy code incremental fix)
  if (cond?.moduleType?.length > 0) {
    // @ts-expect-error (legacy code incremental fix)
    cond.moduleType?.forEach((type) => {
      if (type in formValue.moduleType.condition) {
        (formValue.moduleType.condition as LegacyAny)[type] = true;
        formValue.moduleType.enabled = true;
      }
    });
  }
  // @ts-expect-error (legacy code incremental fix)
  if (cond?.status?.length > 0) {
    // @ts-expect-error (legacy code incremental fix)
    cond.status?.forEach((st) => {
      if (st in formValue.status.condition) {
        (formValue.status.condition as LegacyAny)[st] = true;
        formValue.status.enabled = true;
      }
    });
  }
  // @ts-expect-error (legacy code incremental fix)
  if (cond?.subscription?.length > 0) {
    const others: LegacyAny = [];
    formValue.subscription.enabled = true;
    // @ts-expect-error (legacy code incremental fix)
    cond.subscription?.forEach((st) => {
      if (st in formValue.subscription.condition) {
        (formValue.subscription.condition as LegacyAny)[st] = true;
      } else {
        others.push(st);
      }
    });
    if (others.length > 0) {
      formValue.subscription.other = others.join(', ');
    }
  }

  if (cond?.sessionStatus) {
    formValue.sessionStatus.condition =
      cond?.sessionStatus === SubscriberSearchSessionStatus.OFFLINE
        ? 'offline'
        : cond?.sessionStatus === SubscriberSearchSessionStatus.ONLINE
          ? 'online'
          : null;
    formValue.sessionStatus.enabled = formValue.sessionStatus.condition !== null;
  }

  return formValue;
}

@Component({
  selector: 'app-sims-search-box',
  templateUrl: './sims-search-box.component.html',
  styleUrls: ['./sims-search-box.component.scss'],
})
export class SimsSearchBoxComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private fb = inject(FormBuilder);

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('menuButton') menuButton: ElementRef;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('searchTextField') searchTextField: ElementRef;

  readonly searchCategories = textSearchCategories;
  readonly statuses = statuses;
  readonly moduleTypes = moduleTypes;
  readonly sessionStatuses = sessionStatuses;
  // candidates are up to coverage_type and user type, so it will be populated dynamically
  readonly subscriptions: SubscriptionPlanInterface[] = useGetAvailableSubscriptions()();

  readonly defaultFormState: SearchConditionFormValue = {
    text: { enabled: true, category: 'any', text: '' },
    // If you add new default value here, don't forget to add value in moduleTypes const above
    moduleType: {
      enabled: false,
      condition: {
        mini: false,
        micro: false,
        nano: false,
        trio: false,
        embedded: false,
        integrated: false,
        virtual: false,
        profilePackage: false,
      },
    },
    // If you add new default value here, don't forget to add value in statuses const above
    status: {
      enabled: false,
      condition: {
        standby: false,
        ready: false,
        shipped: false,
        active: false,
        inactive: false,
        suspended: false,
        terminated: false,
      },
    },
    subscription: {
      enabled: false,
      condition: this.subscriptions.reduce(
        (obj, plan) => {
          obj[plan.planName] = false;
          return obj;
        },
        {} as Record<SubscriptionPlanName, boolean>,
      ),
      other: '',
    },
    sessionStatus: {
      enabled: false,
      condition: null,
    },
  };

  // ==== State ===

  // summary labels of current search condition displayed in the textbox
  // @ts-expect-error (legacy code incremental fix)
  conditionSummary: Array<{ label: string; value: string }>;

  @Input() set condition(condition: SearchCondition | null | undefined) {
    // set only if the extracted condition differs from the input in order to preserve form state
    if (!isEqual(condition, this.extractCondition())) {
      const formValue = convertSearchConditionToFormValue(condition, this.defaultFormState);
      this.form.setValue(formValue);

      // extractCondition() refers this.form so it returns different value
      this.conditionSummary = this.summarizeCondition(this.extractCondition());
    }
  }

  @Output() onSearch = new EventEmitter<SearchCondition>();

  get isDirty() {
    return this.conditionSummary?.length > 0;
  }

  constructor(
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
  ) {}

  form = this.fb.nonNullable.group({
    text: this.fb.group({
      enabled: this.defaultFormState.text.enabled,
      category: this.defaultFormState.text.category,
      text: [this.defaultFormState.text.text, [Validators.minLength(2), minNonSpaceCharValidator(2)]], // override to put validator
    }),
    moduleType: this.fb.group({
      enabled: this.defaultFormState.moduleType.enabled,
      condition: buildFormGroupFromArray(moduleTypes, false),
    }),
    status: this.fb.group({
      enabled: this.defaultFormState.status.enabled,
      condition: buildFormGroupFromArray(statuses, false),
    }),
    subscription: this.fb.group({
      enabled: this.defaultFormState.subscription.enabled,
      condition: this.fb.group(this.defaultFormState.subscription.condition),
      other: this.defaultFormState.subscription.other,
    }),
    sessionStatus: this.fb.group({
      enabled: this.defaultFormState.sessionStatus.enabled,
      condition: this.fb.control<SessionStatusForSearch>(null),
    }),
  });

  ngOnInit(): void {
    this.updateDisabledStateOfControls();
    this.form.valueChanges.pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.updateDisabledStateOfControls();
    });
  }

  updateDisabledStateOfControls() {
    // @ts-expect-error (legacy code incremental fix)
    const textEnabledControl = this.form.get('text').get('enabled');
    this.isTextSearchDisabled()
      ? // @ts-expect-error (legacy code incremental fix)
        textEnabledControl.disable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        textEnabledControl.enable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const textCategoryControl = this.form.get('text').get('category');
    this.isTextSearchEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        textCategoryControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        textCategoryControl.disable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const textTextControl = this.form.get('text').get('text');
    this.isTextSearchEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        textTextControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        textTextControl.disable({ emitEvent: false });

    // @ts-expect-error (legacy code incremental fix)
    const moduleTypeEnabledControl = this.form.get('moduleType').get('enabled');
    this.isModuleTypeFilterDisabled()
      ? // @ts-expect-error (legacy code incremental fix)
        moduleTypeEnabledControl.disable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        moduleTypeEnabledControl.enable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const moduleTypeConditionControl = this.form.get('moduleType').get('condition');
    this.isModuleTypeFilterEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        moduleTypeConditionControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        moduleTypeConditionControl.disable({ emitEvent: false });

    // @ts-expect-error (legacy code incremental fix)
    const statusEnabledControl = this.form.get('status').get('enabled');
    this.isStatusFilterDisabled()
      ? // @ts-expect-error (legacy code incremental fix)
        statusEnabledControl.disable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        statusEnabledControl.enable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const statusConditionControl = this.form.get('status').get('condition');
    this.isStatusFilterEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        statusConditionControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        statusConditionControl.disable({ emitEvent: false });

    // @ts-expect-error (legacy code incremental fix)
    const subscriptionEnabledControl = this.form.get('subscription').get('enabled');
    this.isSubscriptionFilterDisabled()
      ? // @ts-expect-error (legacy code incremental fix)
        subscriptionEnabledControl.disable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        subscriptionEnabledControl.enable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const subscriptionConditionControl = this.form.get('subscription').get('condition');
    this.isSubscriptionFilterEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        subscriptionConditionControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        subscriptionConditionControl.disable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const subscriptionOtherControl = this.form.get('subscription').get('other');
    this.isSubscriptionFilterEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        subscriptionOtherControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        subscriptionOtherControl.disable({ emitEvent: false });

    // @ts-expect-error (legacy code incremental fix)
    const sessionStatusEnabledControl = this.form.get('sessionStatus').get('enabled');
    this.isSessionStatusFilterDisabled()
      ? // @ts-expect-error (legacy code incremental fix)
        sessionStatusEnabledControl.disable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        sessionStatusEnabledControl.enable({ emitEvent: false });
    // @ts-expect-error (legacy code incremental fix)
    const sessionStatusConditionControl = this.form.get('sessionStatus').get('condition');
    this.isSessionStatusFilterEditable()
      ? // @ts-expect-error (legacy code incremental fix)
        sessionStatusConditionControl.enable({ emitEvent: false })
      : // @ts-expect-error (legacy code incremental fix)
        sessionStatusConditionControl.disable({ emitEvent: false });
  }

  get formValue(): SearchConditionFormValue {
    // @ts-expect-error (legacy code incremental fix)
    return this.form.getRawValue();
  }

  get searchCategoryFormValue(): string {
    return this.formValue.text.category;
  }

  onToggleMenuButton() {
    this.searchTextField.nativeElement.focus();
  }

  isUsingAnySearch(): boolean {
    return this.searchCategoryFormValue === 'any' && this.isUsingTextSearch();
  }

  isUsingTextSearch(): boolean {
    // @ts-expect-error (legacy code incremental fix)
    return this.formValue.text.enabled && this.formValue.text.text.length >= 2;
  }

  isTextSearchEditable(): boolean {
    return this.formValue.text.enabled && !this.isTextSearchDisabled();
  }

  isTextSearchDisabled(): boolean {
    return (
      this.hasMultipleQueries('moduleType') ||
      this.hasMultipleQueries('status') ||
      this.hasMultipleQueries('subscription')
    );
  }

  isStatusFilterEditable(): boolean {
    return this.formValue.status.enabled && !this.isStatusFilterDisabled();
  }

  isStatusFilterDisabled(): boolean {
    return this.isUsingAnySearch() || this.hasMultipleQueries('moduleType') || this.hasMultipleQueries('subscription');
  }

  isSessionStatusFilterEditable(): boolean {
    return this.formValue.sessionStatus.enabled && !this.isSessionStatusFilterDisabled();
  }

  isSessionStatusFilterDisabled(): boolean {
    return (
      this.isUsingAnySearch() ||
      this.hasMultipleQueries('moduleType') ||
      this.hasMultipleQueries('status') ||
      this.hasMultipleQueries('subscription')
    );
  }

  isModuleTypeFilterEditable(): boolean {
    return this.formValue.moduleType.enabled && !this.isModuleTypeFilterDisabled();
  }

  isModuleTypeFilterDisabled(): boolean {
    return this.isUsingAnySearch() || this.hasMultipleQueries('status') || this.hasMultipleQueries('subscription');
  }

  isSubscriptionFilterEditable(): boolean {
    return this.formValue.subscription.enabled && !this.isSubscriptionFilterDisabled();
  }

  isSubscriptionFilterDisabled(): boolean {
    return this.isUsingAnySearch() || this.hasMultipleQueries('moduleType') || this.hasMultipleQueries('status');
  }

  isComplicatedCondition(): boolean {
    return (
      this.hasMultipleQueries('moduleType') ||
      this.hasMultipleQueries('status') ||
      this.hasMultipleQueries('subscription')
    );
  }

  isFormEmpty(): boolean {
    return !(
      this.isUsingTextSearch() ||
      this.hasQuery('moduleType') ||
      this.hasQuery('status') ||
      this.hasQuery('sessionStatus') ||
      this.hasQuery('subscription')
    );
  }

  close() {
    this.menuButton.nativeElement.removeAttribute('open');
  }

  resetForm(event?: LegacyAny) {
    if (event) {
      event.preventDefault();
    }
    this.form.setValue(this.defaultFormState);
  }

  clear() {
    this.resetForm();
    this.conditionSummary = this.summarizeCondition(this.extractCondition());
    // @ts-expect-error (legacy code incremental fix)
    this.onSearch.emit(null);
  }

  search() {
    const currentCondition = this.extractCondition();
    this.conditionSummary = this.summarizeCondition(currentCondition);
    // @ts-expect-error (legacy code incremental fix)
    this.onSearch.emit(this.isFormEmpty() ? null : currentCondition);
    this.close();
  }

  private extractCondition(): SearchCondition {
    const condition: SearchCondition = {
      searchType: SubscriberSearchType.AND,
    };
    const value = this.formValue;
    // @ts-expect-error (legacy code incremental fix)
    if (this.isTextSearchEditable() && value.text.text?.length > 1) {
      condition.text = { category: value.text.category, value: value.text.text };
    }

    if (this.isSessionStatusFilterEditable() && value.sessionStatus.condition) {
      condition.sessionStatus = SubscriberSearchSessionStatusFactory.createFrom(
        value.sessionStatus.condition === 'online',
        value.sessionStatus.condition === 'offline',
      );
    }

    if (this.isStatusFilterEditable()) {
      condition.status = this.getSelectedValues(value.status);
    }

    if (this.isModuleTypeFilterEditable()) {
      condition.moduleType = this.getSelectedValues(value.moduleType);
    }

    if (this.isSubscriptionFilterEditable()) {
      condition.subscription = this.getSelectedValues(value.subscription);
    }

    if (this.isComplicatedCondition() || this.isUsingAnySearch()) {
      condition.searchType = SubscriberSearchType.OR;
    }

    return condition;
  }

  private summarizeCondition(condition: SearchCondition): Array<{ label: string; value: string }> {
    const summary: Array<{ label: string; value: string }> = [];
    if (condition.text?.value) {
      const label = this.translateService.instant(
        `SimsSearchBoxComponent.values.searchCategory.${condition.text.category}`,
      );
      summary.push({ label, value: condition.text.value });
    }
    if (condition.sessionStatus) {
      const label = this.translateService.instant('SimsSearchBoxComponent.searchQueryKey.sessionStatus');
      summary.push({ label, value: condition.sessionStatus });
    }
    ['status', 'moduleType', 'subscription'].forEach((key) => {
      if ((condition as LegacyAny)[key]?.length > 0) {
        const label = this.translateService.instant(`SimsSearchBoxComponent.searchQueryKey.${key}`);
        let conditionItems = (condition as LegacyAny)[key];
        if (key !== 'subscription') {
          conditionItems = conditionItems.map((value: LegacyAny) =>
            this.translateService.instant(`SimsSearchBoxComponent.values.${key}.${value}`),
          );
        }
        summary.push({ label, value: conditionItems.join(', ') });
      }
    });

    return summary;
  }

  private getSelectedValues(formValue: {
    enabled: boolean;
    condition: Record<string, boolean>;
    other?: string;
  }): string[] {
    if (!formValue.enabled) {
      return [];
    }
    const values = Object.keys(formValue.condition).filter((key) => formValue.condition[key]);
    const otherValue = (formValue.other as string) || '';
    if (otherValue) {
      values.push(
        ...otherValue
          .split(',')
          .map((v) => v?.trim())
          .filter((v) => v?.length > 1),
      );
    }
    return values;
  }

  private hasMultipleQueries(filterName: 'moduleType' | 'sessionStatus' | 'status' | 'subscription'): boolean {
    return this.hasSelectedItem(filterName, 1);
  }

  private hasQuery(filterName: 'moduleType' | 'sessionStatus' | 'status' | 'subscription'): boolean {
    return this.hasSelectedItem(filterName, 0);
  }

  private hasSelectedItem(filterName: 'moduleType' | 'sessionStatus' | 'status' | 'subscription', moreThan: number) {
    const formValue = this.formValue[filterName];
    const condition = formValue.condition;
    if (!formValue.enabled || !condition) {
      return false;
    }
    if (typeof condition === 'object') {
      const otherValue = (formValue as SearchConditionFormValue['subscription'])?.other ?? '';
      const selectedValues = Object.keys(condition).filter((k) => (condition as LegacyAny)[k] === true);
      if (otherValue) {
        selectedValues.push(
          ...otherValue
            .split(',')
            .map((v) => v?.trim())
            .filter((v) => v?.length > 1),
        );
      }
      return selectedValues.length > moreThan;
    } else {
      return Boolean(condition);
    }
  }
}

/**
 * Returns a function to get available subscription plans dynamically, which varies depending on user and coverage_type
 * Note that the result array are already sorted for display
 * TODO: move to an appropriate library onces dependent services moved to libraries
 */
function useGetAvailableSubscriptions() {
  const coverageTypeService = inject(CoverageTypeService);
  const featureVisibilityService = inject(FeatureVisibilityService);

  return function getAvailableSubscriptions() {
    const subscriptions = [];

    const coverageType = coverageTypeService.getCoverageType();
    if (featureVisibilityService.isEnabled('planUS')) {
      subscriptionsMap.g.push(SubscriptionPlan.get('plan-US'), SubscriptionPlan.get('plan-US-NA'));
    }
    if (
      getOperatorData().isKddiUser() &&
      coverageTypeService.isGlobal() &&
      getOperatorData().getKddiOrganizationCode() === KddiDepartmentCode.KDDIKIA
    ) {
      subscriptions.push(...subscriptionsMap.kddikia);
    } else if (getOperatorData().isKddiUser() && coverageTypeService.isGlobal()) {
      subscriptions.push(...subscriptionsMap.kddigcp);
    } else {
      // @ts-expect-error (legacy code incremental fix)
      subscriptions.push(...subscriptionsMap[coverageType]);
    }

    // Here is the logic to add hidden plans. This should be removed when this ticket is implemented: https://app.shortcut.com/soracom/story/51902/implement-a-single-canonical-way-to-know-which-subscription-plans-are-available
    const addPlanK2 = featureVisibilityService.isEnabled('planK2');
    if (addPlanK2) {
      if (coverageType === 'jp') {
        const planK2 = SubscriptionPlan.get('plan-K2');
        subscriptions.splice(3, 0, planK2);
      }
    }
    return subscriptions;
  };
}

/**
 * Returns new FormGroup whose keys come from the argument `keys`.
 * @param keys: ReadonlyArray<K>: K is expected to be union of literal types and `keys` is expected to covers all the possible value of K
 * @returns FormGroup
 */
function buildFormGroupFromArray<K extends string, T>(
  keys: readonly K[],
  formControlInitialState?: T | FormControlState<T>,
  formControlOptions?: FormControlOptions,
): FormGroup<Record<K, FormControl<T>>> {
  const controls = keys.reduce(
    (obj, key) => {
      // @ts-expect-error (legacy code incremental fix)
      obj[key] = new FormControl(formControlInitialState, formControlOptions);
      return obj;
    },
    {} as Record<K, FormControl<T>>,
  );

  return new FormGroup(controls);
}
