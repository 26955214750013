export enum SubscriberSearchSessionStatus {
  NA = 'NA',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export class SubscriberSearchSessionStatusFactory {
  static createFrom(onlineOnly: boolean, offlineOnly: boolean): SubscriberSearchSessionStatus {
    if (onlineOnly === offlineOnly) {
      return SubscriberSearchSessionStatus.NA;
    }

    if (onlineOnly) {
      return SubscriberSearchSessionStatus.ONLINE;
    }

    return SubscriberSearchSessionStatus.OFFLINE;
  }
}
