export const HarvestVizTypes = ['line_chart', 'grouped_column_chart', 'stacked_column_chart', 'map', 'table'] as const;
export type HarvestDataVizType = typeof HarvestVizTypes[number];
export type HarvestChartType = Extract<
  HarvestDataVizType,
  'line_chart' | 'grouped_column_chart' | 'stacked_column_chart'
>;

export const isHarvestDataVizType = (type: string): type is HarvestDataVizType =>
  HarvestVizTypes.some((v) => v === type);

export interface AxisControlState {
  autoAdjust: boolean;
  maxValue?: number | null;
  minValue?: number | null;
}

export interface DataSeriesOption {
  label: string;
  backgroundColor: string;
  borderColor: string;
}

export type DataSeriesVisibility = {
  [key: string]: boolean;
};
