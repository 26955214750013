<div class="ds-cols--small">
  <details class="ds-menubutton" #menuButton (toggle)="onToggleMenuButton()">
    <summary clickCapturesPosition>
      <span class="ds-button --icon-search search-summary x-search-box-toggle">
        <span class="ds-tag --color-celeste-lighter --no-case" *ngFor="let summary of conditionSummary">
          <strong>{{ summary.label }}</strong
          >{{ summary.value }}</span
        >
        <i class="ds-icon --icon-ui-arrow-down"></i>
      </span>
    </summary>
    <form class="__content ds-rows" [formGroup]="form" (ngSubmit)="search()" novalidate tabindex="-1">
      <div class="x-search-by-text" formGroupName="text">
        <label class="ds-checkbox">
          <input
            type="checkbox"
            class="x-enabled"
            formControlName="enabled"
          />
          <p class="ds-text --label">{{ 'SimsSearchBoxComponent.searchByText' | translate }}</p>
        </label>
        <div class="ds-cols">
          <select
            class="ds-select x-search-by-text-category"
            formControlName="category"
          >
            <option *ngFor="let category of searchCategories" [value]="category">
              {{ 'SimsSearchBoxComponent.values.searchCategory.' + category | translate }}
            </option>
          </select>
          <input
            class="ds-span --expand ds-input x-search-by-text-value"
            formControlName="text"
            #searchTextField
            [attr.placeholder]="'SimsSearchBoxComponent.searchPlaceholder.' + searchCategoryFormValue | translate"
          />
        </div>
      </div>
      <div class="x-sessionStatus" formGroupName="sessionStatus">
        <label class="ds-checkbox">
          <input
            type="checkbox"
            class="x-enabled"
            formControlName="enabled"
          />
          <p class="ds-text--label">{{ 'SimsSearchBoxComponent.filterBySessionStatus' | translate }}</p>
        </label>
        <div>
          <label *ngFor="let item of sessionStatuses" class="ds-tag --medium --outline --no-case">
            <input
              type="radio"
              name="condition"
              formControlName="condition"
              [value]="item"
            />
            <span>
              {{ 'SimsSearchBoxComponent.values.sessionStatus.' + item | translate }}
            </span>
          </label>
        </div>
      </div>
      <div class="x-status" formGroupName="status">
        <label class="ds-checkbox">
          <input
            type="checkbox"
            class="x-enabled"
            formControlName="enabled"
          />
          <p class="ds-text--label">{{ 'SimsSearchBoxComponent.filterByStatus' | translate }}</p>
        </label>
        <div formGroupName="condition">
          <label *ngFor="let status of statuses" class="ds-tag --medium --outline --no-case">
            <input
              type="checkbox"
              class="x-status--{{ status }}"
              [formControlName]="status"
            />
            <span>
              {{ 'SimsSearchBoxComponent.values.status.' + status | translate }}
            </span>
          </label>
        </div>
      </div>
      <div class="x-subscription" formGroupName="subscription">
        <label class="ds-checkbox">
          <input
            type="checkbox"
            class="x-enabled"
            formControlName="enabled"
            [attr.disabled]="isSubscriptionFilterDisabled() ? '' : null"
          />
          <p class="ds-text--label">{{ 'SimsSearchBoxComponent.filterBySubscription' | translate }}</p>
        </label>
        <div formGroupName="condition">
          <label *ngFor="let subscription of subscriptions" class="ds-tag --medium --outline --no-case">
            <input
              type="checkbox"
              [formControlName]="subscription.planName"
              [attr.disabled]="!isSubscriptionFilterEditable() ? '' : null"
            />
            <span>{{ subscription.description() | text }}</span>
          </label>
        </div>
        <div class="ds-cols" style="margin-top: 8px">
          <input
            type="text"
            formControlName="other"
            class="ds-input ds-span--6"
            [attr.placeholder]="'SimsSearchBoxComponent.subscription.other' | translate"
          />
        </div>
      </div>
      <div class="x-moduleType" formGroupName="moduleType">
        <label class="ds-checkbox">
          <input
            type="checkbox"
            class="x-enabled"
            formControlName="enabled"
          />
          <p class="ds-text--label">{{ 'SimsSearchBoxComponent.filterByModuleType' | translate }}</p>
        </label>
        <div formGroupName="condition">
          <ng-container *ngFor="let moduleType of moduleTypes">
            <label *ngIf="form.get('moduleType.condition.' + moduleType)" class="ds-tag --medium --outline --no-case">
              <input
                type="checkbox"
                [formControlName]="moduleType"
              />
              <span>
                {{ 'SimsSearchBoxComponent.values.moduleType.' + moduleType | translate }}
              </span>
            </label>
          </ng-container>
        </div>
      </div>
      <div *ngIf="isComplicatedCondition()" class="ds-notice --pinned --info">
        {{ 'SimsSearchBoxComponent.howToUse' | translate }}
      </div>
      <div *ngIf="isUsingAnySearch()" class="ds-notice --pinned --info">
        <!-- the nested div below is a workaround for https://app.shortcut.com/soracom/story/75617/text-and-icons-overlapping-in-search-ui and should be able to be removed when SDS is fixed -->
        <div style='margin-left: 1.5em'>{{ 'SimsSearchBoxComponent.filterIsNotAvailable' | translate }}</div>
      </div>
      <div class="ds-cols --end --small">
        <button type="button" class="ds-button x-subscriber-cancel-search-button" (click)="close()">
          {{ 'SimsSearchBoxComponent.close' | translate }}
        </button>
        <button type="button" class="ds-button x-subscriber-reset-search-button" (click)="resetForm($event)">
          {{ 'SimsSearchBoxComponent.reset' | translate }}
        </button>
        <button
          type="submit"
          class="ds-button --primary x-subscriber-search-button"
          [disabled]="form.invalid"
        >
          {{ 'SimsSearchBoxComponent.searchButton' | translate }}
        </button>
      </div>
    </form>
  </details>
  <button class="ds-button --plain --icon-cancel" (click)="clear()" *ngIf="isDirty">
    <span>{{ 'SimsSearchBoxComponent.clearSearch' | translate }}</span>
  </button>
</div>
